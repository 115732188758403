<template>
  <div class="renewlist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">续费确认</span>
    </div>
    <div class="content">
      <div class="list-top">
        <div class="tppaycost">待续费资源</div>
      </div>
      <com-table class="renewlist"
                 :headercellstyle="headercellstyle"
                 :columns="lineRenewList"
                 :data="tabledata"
                 tableheight="400"
                 :pagination="pagination">
      </com-table>
    </div>

    <div class="content-right">
      <div class="ffxx">
        <p class="title-wrap">
          <i class="iconfont icon-zhongbiao titleicon"></i><span class="title">购买时长</span>
        </p>
        <div class="select-sc">
          <div class="select-item">
            <!-- <div @click="selectsc(7)"
                 :class="['day', { 'sc-active': sc == 7 }]">
              <span>周付</span>
            </div> -->
            <div @click="selectsc(30)"
                 :class="['month', { 'sc-active': sc == 30 }]">
              <span>月付</span>
            </div>
            <div @click="selectsc(90)"
                 :class="['season', { 'sc-active': sc == 90 }]">
              <span>季付</span>
              <span class="sc-discount">(97折)</span>
            </div>
            <div @click="selectsc(180)"
                 :class="['halfyear', { 'sc-active': sc == 180 }]">
              <span>半年付</span>
              <span class="sc-discount">(95折)</span>
            </div>
            <div @click="selectsc(360)"
                 :class="['year', { 'sc-active': sc == 360 }]">
              <span>年付</span>
              <span class="sc-discount">(9折)</span>
            </div>
          </div>
        </div>
        <p class="title-wrap">
          <i class="iconfont icon-zhongbiao titleicon"></i>
          <span class="title">订单数量</span>
        </p>
        <div class="ff-detail">
          <span class="dttext">{{ detailform.num }} 个</span>
        </div>
        <p class="title-wrap">
          <i class="iconfont icon-zhongbiao titleicon"></i>
          <span class="title">合计费用</span>
        </p>
        <div class="ff-detail">
          <span class="dtprice">{{ detailform.total }} 元</span>
        </div>
        <div class="ff-detail">
          <el-radio-group class="payradio"
                          v-model="payform.PayType">
            <el-radio label="amount"
                      :disabled="balanceNotAvailable"
                      class="itemradio">余额支付(可用:{{ balance }})</el-radio>
            <el-radio label="wechatpay_native"
                      class="itemradio"><i class="iconfont icon-weixinzhifu"></i>微信支付</el-radio>
            <el-radio label="alipay_page"
                      class="itemradio">
              <div class="itemradio-content"><img src="@/assets/icon-alipay.png"
                     class="icon-alipay" />支付宝支付</div>
            </el-radio>
          </el-radio-group>
          <el-button @click="makepayment"
                     class="paybtn"
                     size="small"
                     :disabled="payDisable"
                     type="primary">立即支付</el-button>
        </div>
      </div>
      <pay-renewal ref="payrenewal">
      </pay-renewal>
    </div>
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import { lineRenewList } from "@/utils/tableList";
import PayRenewal from "@/components/PayRenewal"
export default {
  name: 'renewconfirm',
  data () {
    return {
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      tabledata: [],
      lineRenewList,
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      showdelete: false,
      row: {},
      title: "删除云手机",
      payradio: 0,
      sc: 1,
      detailform: {
        num: "0",
        sc: "1月",
        total: "--",
      },
      renewform: {
        ResourceId: [],
        ResourceType: "",
        ChargeId: 1,
        CreateOrder: false,
      },
      payform: {
        TradeNo: "",
        TotalAmount: "",
        ResourceType: "",
        PayType: "amount",
      },
      balanceNotAvailable: false,
      balance: 0,
      payDisable: false,
    };
  },
  components: { ComTable, PayRenewal },
  watch: {
    sc: {
      handler: function (val) {
        //价格改为从后台获取，无需前端计算
        this.renewform.ChargeId = this.sc;
        this.$store.dispatch("line/createreneworder", { ...this.renewform })
          .then((res) => {
            if (res.RetCode == 0) {
              this.detailform.total = res.TotalAmount / 100;
              if (this.detailform.total > this.balance) {
                this.balanceNotAvailable = true;
              } else {
                this.balanceNotAvailable = false;
              }
            } else {
              console.log("failed to get renew fee: " + res.Message);
            }
          });
      },
      deep: true,
    },
    balance: {
      handler: function (val) {
        if (this.detailform.total > this.balance) {
          this.balanceNotAvailable = true;
        } else {
          this.balanceNotAvailable = false;
        }
      },
      deep: true,
    }
  },
  methods: {
    selectsc (val) {
      this.sc = val;
      switch (val) {
        // case 7:
        //   this.detailform.sc = "一周";
        //   this.discount = 1.0;
        //   break;
        case 30:
          this.detailform.sc = "一月";
          this.discount = 1.0;
          break;
        case 90:
          this.detailform.sc = "季度";
          this.discount = 0.9;
          break;
        case 180:
          this.detailform.sc = "半年";
          this.discount = 0.8;
          break;
      }
    },
    makepayment () {
      //先创建续费订单
      if (this.payDisable) {
        return
      }
      this.payDisable = true
      this.renewform.CreateOrder = true;
      this.$store
        .dispatch("line/createreneworder", { ...this.renewform })
        .then((res) => {
          if (res.RetCode == 0) {
            if (this.payform.PayType == "amount") {
              this.payByAmount(res.TradeNo);
            } else {
              this.payform.TotalAmount = res.TotalAmount / 100;//分转换为元
              this.payform.TradeNo = res.TradeNo;
              this.$refs.payrenewal.openPayDialog(this.payform);
            }
          } else {
            console.log("failed to get renew fee: " + res.Message);
          }
        })
        .finally(() => {
          this.payDisable = false
        });
    },
    payByAmount (tradeNo) {
      const payload = {
        TradeNo: tradeNo,
        PayType: this.payform.PayType,
        OrderType: "Renew"
      };
      this.$store.dispatch("line/getpaymentparams", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("支付成功！");
            if (this.payform.ResourceType == "Path") {
              this.$router.push({ path: '/line/linelist' });
            } else {
              this.$router.push({ path: '/phone/phonelist' });
            }
          }
        });
    },
    getUserBalance () {
      this.$store.dispatch("user/getuserwallet").then((res) => {
        // console.log("user wallet: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.balance = res.Balance / 100;
        }
      });
    },
  },
  mounted () {
    // console.log("renew params: " + JSON.stringify(this.$route.params));
    var routepayload = this.$route.params.payload;
    if (routepayload != undefined) {
      let count = 0;
      this.tabledata = Object.keys(routepayload).map(key => {
        count++;
        // console.log(key); //为每个键名
        this.renewform.ResourceId.push(routepayload[key].id);
        this.renewform.ResourceType = routepayload[key].product_type;
        this.payform.ResourceType = routepayload[key].product_type;
        return routepayload[key];  //把每个对象返回出去生成一个新的数组中相当于0:{id:1}
      });
      // console.log(this.tabledata)
      this.detailform.num = count;
      this.sc = 30;
    }
    this.getUserBalance();
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>